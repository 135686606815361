import type { App } from "vue";
import { VueQueryPlugin } from "@tanstack/vue-query";
import { queryClient } from "@/services/queryClient";
import { createNotivueAstro } from "notivue";

export default (app: App) => {
	const notivue = createNotivueAstro({
		limit: 20,
		position: "bottom-right",
		pauseOnHover: true,
		pauseOnTabChange: true,
		notifications: {
			success: {
				duration: 2500,
			},
		},
	});

	if (import.meta.env.DEV) {
		app.config.warnHandler = (msg, _vm, trace) => {
			if (msg.includes("Extraneous non-props attributes")) {
				return;
			}
			console.warn(`[Vue warn]: ${msg}\n  ${trace.split("\n").join("\n  ")}`);
		};
	}

	app.use(notivue).use(VueQueryPlugin, { queryClient });
};
